import { Injectable } from '@angular/core';
import { Userpilot } from 'userpilot';
import { UserService } from '../user-service/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserpilotService {
  constructor(private userService: UserService) {}

  public get userpilot(): typeof Userpilot {
    return Userpilot;
  }

  public isActive(): boolean {
    return !!(window as any)?.userpilot?.isRunning()?.active;
  }

  public trigger(id: string, onlyOnce: boolean = false) {
    this.userService.getFuturaUser().subscribe(user => {
      if (user.content.tutorials_seen[id] && onlyOnce) {
        return;
      }

      this.userpilot.trigger(id);

      user.content.tutorials_seen[id] = new Date().getTime();
      this.userService.updateFuturaUser(user).subscribe();
    });
  }
}
